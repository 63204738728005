import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"


const LoginForm = ({ onSubmit }) => {
    return (

        // < !--4: 3 aspect ratio-- >
        <div className="wrape-video-modal">
            <iframe src="https://www.youtube.com/embed/AMuzW4PGW1g?autoplay=1&mute=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

    );
};



export default function Videotheree() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onLoginFormSubmit = (e) => {
        e.preventDefault();
        handleClose();
    };
    return (
        <>
            {/* wrappe image video pop-up */}
            <div className="wrp-video-pop-up-hlg-oto-bp d-flex justify-content-center">
                <Button onClick={handleShow}>
                    <img className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/thumbnail-oto-video-bp.jpg" alt="video youtube" />
                </Button>
            </div>
            {/* end wrappe image video pop-up */}

            <Modal
                show={show} onHide={handleClose}
                size="lg"
                centered
                dialogClassName="modal-100w"
                className="w-modal-video"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Video
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </>

    )
}