import React from "react"
import { Helmet } from "react-helmet"
import { CheckCircle } from 'react-bootstrap-icons';
import { FaTimes, FaCheck, FaLongArrowAltDown } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import { Accordion, Card, Button } from "react-bootstrap";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import VideoModalBsTheree from '../components/section/modalVideo/videoTheree';


class Orderstep2 extends React.Component {

    render() {
        return (
            <>

                <React.Fragment>
                    <div className="application">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>One Time Offer Bahasa Pria</title>
                        </Helmet>
                    </div>
                    <div className="container-fluid __bg-oto-bp">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text-new-oto">
                                        <p className="text-white _bg-blink">
                                            <b>
                                                Selangkah Lagi Untuk Selesaikan Order Buku Kamu
                                            </b>
                                        </p>
                                        <h3 className="text-white">
                                            DAPATKAN PENAWARAN EKSKLUSIF <br /> KHUSUS DENGAN ONLINE COURSE BAHASA PRIA!
                                        </h3>
                                        <h2 className="text-white">
                                            <b>
                                                Dapatkan Hampir Apapun Dari Pria, <br /> Hanya Melalui Kata - Kata …Termasuk Hatinya!
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe image video pop-up */}
                                    <VideoModalBsTheree />
                                    {/* end wrappe image video pop-up */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <a href="https://wa.link/u356sj" rel="noreferrer" target="_blank">
                                            <button type="button" className="btn btn-purple">
                                                <FaTimes />  Tidak, Saya Lewatkan Penawaran Eksklusifnya
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <AnchorLink href="#order">
                                            <button type="button" className="btn btn-danger">
                                                <FaCheck />  Ya, Saya Mau, Apa Saja Yang <br /> Didapat
                                            </button>
                                        </AnchorLink>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text-new-oto">
                                        <h3 className="text-white">
                                            Jika Kamu ingin dimengerti dan memiliki hubungan yang membahagiakan, maka cara terbaik untuk mencapainya adalah dengan mengerti dan memberikan apa yang pria butuhkan.
                                        </h3>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end  section firts */}

                    {/* section four */}
                    <div className="container-fluid bg-white __pdng-top-bottom-oto">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text">
                                        <h2 className="text-drak">
                                            <b>
                                                PENAWARAN EKSKLUSIF YANG TIDAK AKAN KAMU TEMUKAN DIMANAPUN
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe image video pop-up */}
                                    <div className="images-thumbnail-book-hlg-oto d-flex justify-content-center">
                                        <img className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/Mockup-ebook-BP-buku-HLG.png" alt="product hlg" />
                                    </div>
                                    {/* end wrappe image video pop-up */}
                                </div>
                                <div className="col-lg-12">
                                    <div className="__wrappe-headline-hlg-bp-oto">
                                        <h3>
                                            <b>Happy Love Guide</b>
                                        </h3>
                                        <h3>
                                            Semakin Luar Biasa dengan
                                        </h3>
                                        <h3>
                                            <b>Bahasa Pria</b>
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text-new">
                                        <h2 className="text-drak">
                                            <b>
                                                Perkenalkan Bahasa Pria Online Course
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe image video pop-up */}
                                    <div className="images-thumbnail-book-hlg-oto d-flex justify-content-center">
                                        <img className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-mockuop-all-device.png" alt="product hlg" />
                                    </div>
                                    {/* end wrappe image video pop-up */}
                                </div>
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text-new">
                                        <h2 className="text-drak">
                                            <b>
                                                Apa itu Bahasa Pria?
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* wrappe check list what you give */}
                                    <div className="card">
                                        <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-30">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-12">
                                                    <div className="__wrappe-text-what-bp">
                                                        <p>
                                                            Bahasa Pria adalah sebuah Online Course dengan format 50 Video dengan durasi 9+ jam, yang memudahkan kamu mengerti dan memberikan apa yang pria butuhkan.
                                                        </p>
                                                        <p>
                                                            <i>Pernahkah kamu merasa..</i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Bingung cara berkenalan dengan pria potensial yang ada di hadapanmu tanpa takut ditolak
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Kehabisan topik obrolan menarik ketika sedang PDKT
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Ingin meningkatkan kemesraan meskipun rasa bosan pernah melanda
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Bosan bertengkar dan frustasi menghadapi kebiasaan buruknya.
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Gak paham cara buat dia lebih “terbuka” kepadamu
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Ingin membuatnya terus-terusan jatuh cinta kepadamu
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Ingin cepat akrab tanpa terlihat agresif
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Ingin menjadi prioritasnya meskipun dia sedang “sibuk”
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <RiErrorWarningFill className="bg-white-icon-circle-new" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give">
                                                        Hubungan stuck dan ingin dia lebih serius lagi denganmu..
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                            <div className="row justify-content-center">
                                                <div className="col-lg-12">
                                                    <div className="__wrappe-text-what-bp">
                                                        <p><b>Apa sih yang pria benar-benar inginkan?</b></p>
                                                        <p>
                                                            Entah kenapa komunikasi antara wanita dan pria selalu saja melalui jalan terjal. Alih-alih terbentuknya sikap saling mengerti,
                                                            seringnya malah berujung pada konflik.
                                                        </p>
                                                        <p>
                                                            Dan jawaban paling tepat untuk menjawab keluhan tersebut adalah pernyataan dari
                                                            Dr. Stephen Covey dalam bukunya <b>“7 Habits of Highly Effective People”</b>, yang mengatakan
                                                        </p>
                                                        <p>
                                                            <i><b>“Seek First To Understand,</b></i><br />
                                                            <i><b>Then To Be Understood”</b></i>
                                                        </p>
                                                        <p>
                                                            Namun yang sering terjadi, wanita belum paham bahwa <b><i>bahasa pria dan wanita berbeda.</i></b>
                                                        </p>
                                                        <p>
                                                            Padahal jika kamu mengetahui cara "rahasia" ini, kamu bisa <b>tahu formula untuk bisa berkomunikasi dengan pria dan membuat dia merasa jantan dan “ketagihan" ingin membahagiakan kamu!</b>
                                                        </p>
                                                        <p>
                                                            Dan pada akhirnya kamu bisa <b>dapatkan hampir apapun yang kamu mau, termasuk hatinya.</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    {/* end wrappe check list what you give */}
                                </div>
                            </div>
                            <div className="row justify-content-center my-5">
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body _wrappe-testi-oto-bp">
                                            <p>
                                                "Buat kamu yg sedang membangun hubungan dengan pria, ataupun sudah
                                                menikah kamu WAJIB pake BANGET ikutan tutorial bahasa pria ini. Pliss
                                                saya mohon jangan sepelekan ilmu ini, karena saya nyesel banget kenapa baru
                                                tau ilmu sebagus ini sekarang hiks :’( "
                                            </p>
                                            <p><b>-Anty, Head of Finance</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body _wrappe-testi-oto-bp">
                                            <p>

                                                “Kamu harus tau ladies, dari Mr. Jose saya belajar banyak hal mengenai cara
                                                berkomunikasi dengan pria tanpa terkesan murahan, dan maaaasih banyak
                                                lagi tentang cara mewujudkan kehidupan percintaan yang membahagiakan.
                                                Thanks, Mr Jose “
                                            </p>
                                            <p><b>-Nose Fristiani</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body _wrappe-testi-oto-bp">
                                            <p>
                                                “Saya jadi tau apa yang harus saya katakan bukan hanya pada pria, tapi pada semua
                                                orang, menjadi lebih ramah ketika bertemu orang baru, padahal tadinya gak bgt deh!”
                                            </p>
                                            <p><b>-Yoke Febriana</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body _wrappe-testi-oto-bp">
                                            <p>
                                                “Coach, thanks yah untuk guidenya. Dia melamar saya 2 hari yang lalu,
                                                kalau diceritakan seperti mimpi..”
                                            </p>
                                            <p><b>-Sofi</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <a href="https://wa.link/u356sj" rel="noreferrer" target="_blank">
                                            <button type="button" className="btn btn-purple">
                                                <FaTimes />  Tidak, Saya Lewatkan Penawaran Eksklusifnya
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <AnchorLink href="#order">
                                            <button type="button" className="btn btn-danger">
                                                <FaCheck />  Ya, Saya Mau, Apa Saja Yang <br /> Didapat
                                            </button>
                                        </AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end  section four */}

                    {/* section five */}
                    <div className="container-fluid __bg-oto-bp">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text">
                                        <h2 className="text-white">
                                            <b>
                                                Apa saja yang akan dipelajari…
                                            </b>
                                        </h2>
                                        <p className="text-white">
                                            Mungkin Kamu Bertanya-tanya, <br />
                                            Apa Saja Materi Bahasa Pria Yang Bisa Kamu Kuasai
                                        </p>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    {/* wrappe check list what you give */}
                                    <div className="card">
                                        <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-30">
                                            {/* adding give for you */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {/* wrappe adding give for you */}
                                                    <div className="wrappe-adding-given">
                                                        <h4>
                                                            <b>Fase Perkenalan</b>
                                                        </h4>
                                                    </div>
                                                    {/* end wrappe adding give for you */}
                                                </div>
                                            </div>
                                            {/* end adding give for you */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Opennes Mindset & Proactivity
                                                    </p>
                                                    {/* <p className="li-text-checlist-what-you-give">
                                                Drama, bucin dan semua hubungan romansa yang gak pake logika
                                            </p> */}
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Bertemu Pria Baru Tanpa Penolakan
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Buat Dia Ingin Kembali Bertemu Setelah Pertemuan Pertama
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Buka Peluang Cinta Dengan Rekan Kerja
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Buka Peluang Cinta Via Sosial Media
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Kesan Pertama Yang Memorable
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Siapa yang SMS Duluan?
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Bertanya Apakah Dia Single Tanpa Terlihat Neddy<br />
                                                        Total ++17 Materi Video Step By Step
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                        </div>
                                    </div>
                                    {/* end wrappe check list what you give */}
                                </div>
                                <div className="col-lg-12 mt-5">
                                    {/* wrappe check list what you give */}
                                    <div className="card">
                                        <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-30">
                                            {/* adding give for you */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {/* wrappe adding give for you */}
                                                    <div className="wrappe-adding-given">
                                                        <h4>
                                                            <b>Fase Connection</b>
                                                        </h4>
                                                    </div>
                                                    {/* end wrappe adding give for you */}
                                                </div>
                                            </div>
                                            {/* end adding give for you */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Percakapan yang Membuatnya Ingin Terus Berbicara Denganmu
                                                    </p>
                                                    {/* <p className="li-text-checlist-what-you-give">
                                                Drama, bucin dan semua hubungan romansa yang gak pake logika
                                            </p> */}
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Buat Dia Terbuka Denganmu Secara Emosianal
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Dia yang Berusaha Membuatmu Terkesan, Dia yang Jatuh Cinta
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Jadi Wanita yang Terus Membuatnya Terpesona
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Pujian yang Membuatnya Melting
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Tahu Kapan Saatnya yang Tepat (dan berapa banyak) untuk Membuka Masa lalu / <br /> Hal Sensitif <br />
                                                        Total ++15 Materi Video Step By Step
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                        </div>
                                    </div>
                                    {/* end wrappe check list what you give */}
                                </div>
                                <div className="col-lg-12 mt-5">
                                    {/* wrappe check list what you give */}
                                    <div className="card">
                                        <div className="card-body mrgn-top-50 mrgn-bottom-50 mx-l-r-30">
                                            {/* adding give for you */}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    {/* wrappe adding give for you */}
                                                    <div className="wrappe-adding-given">
                                                        <h4>
                                                            <b>Fase Commitment</b>
                                                        </h4>
                                                    </div>
                                                    {/* end wrappe adding give for you */}
                                                </div>
                                            </div>
                                            {/* end adding give for you */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Meningkatkan Value Kamu Dimatanya
                                                    </p>
                                                    {/* <p className="li-text-checlist-what-you-give">
                                                Drama, bucin dan semua hubungan romansa yang gak pake logika
                                            </p> */}
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Darimana Kamu Tahu Bahwa Kalian Sudah Saling Komit
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Menjadi Patner Terbaik Dalam Hidupnya
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Bicara hal -hal Serius Dengan Pria Tanpa Membuatnya Merasa Terpojok
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Berargumen yang MENGUATKAN HUBUNGAN Kalian Berdua
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}
                                            {/* check list what you give */}
                                            <div className="row justify-content-center my-3">
                                                <div>
                                                    <CheckCircle className="bg-white-icon-circle" />
                                                </div>
                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                        Cara Agar Dia Tetap Memperhatikanmu Meskipun Dia "Terlalu" Sibuk <br />
                                                        Total ++19 Materi Video Step By Step
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end check list what you give */}

                                        </div>
                                    </div>
                                    {/* end wrappe check list what you give */}
                                </div>
                            </div>
                            <div className="row justify-content-center my-5">
                                <div className="col-lg-12">
                                    <div className="card my-3">
                                        <div className="card-body _wrappe-testi-oto-bp-new">
                                            <p>
                                                “Semangat untuk searching pria baru, belajar memahami pria dan mempraktekan<br />
                                                langsung itu seru banget, Trust me.. it works! ”
                                            </p>
                                            <p><b>​-Dewi</b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testimoni-hlg-bp-oto.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testimoni-oto-bp-hlg-1.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end btn join this product */}
                        </div>
                    </div>
                    {/*end  section five */}

                    {/* section five */}
                    <div className="container-fluid bg-white __pdng-top-bottom-oto">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text">
                                        <h2 className="text-drak">
                                            <b>
                                                Rincian apa saja yang akan kamu dapat <br />  jika daftar HARI INI…
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* accordion highlight product hlg */}
                                    <Accordion defaultActiveKey="3">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                    <p className="text-collapse-heading">Klik untuk intip isinya…</p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>

                                                    <div className="row mt-5 mb-3">
                                                        <div className="col-lg-12">
                                                            <div className="bonus-images-and-headline">
                                                                <h4 className="text-center">
                                                                    <b>50 Modul Bahasa Pria</b> dalam format video
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-lg-4">
                                                            <div className="d-flex justify-content-center">
                                                                <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-1.png" className="img-fluid" alt="bonus bdtk" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        50 Video dengan durasi 9 jam ++
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Bisa Kamu tonton kapan saja dan dimana saja
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Membahas 3 fase penting dalam relationship
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Ukuran file kecil sehingga tidak menguras kuota
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                    <p className="text-collapse-heading">Klik untuk intip isinya…</p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>

                                                    <div className="row mt-5 mb-3">
                                                        <div className="col-lg-12">
                                                            <div className="bonus-images-and-headline">
                                                                <h4 className="text-center">
                                                                    <b> Downloadable Summary </b> dalam format PDF
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-lg-4">
                                                            <div className="d-flex justify-content-center">
                                                                <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-2.png" className="img-fluid" alt="bonus bdtk" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Bisa di download - memudahkan Kamu mengulang materi hingga ahli
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                                    <p className="text-collapse-heading">Klik untuk intip isinya…</p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="5">
                                                <Card.Body>

                                                    <div className="row mt-5 mb-3">
                                                        <div className="col-lg-12">
                                                            <div className="bonus-images-and-headline">
                                                                <h4 className="text-center">
                                                                    <b> BONUS Ebook Bahasa Pria Fundamental </b>
                                                                    dalam format PDF
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-lg-4">
                                                            <div className="d-flex justify-content-center">
                                                                <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-3.png" className="img-fluid" alt="bonus bdtk" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Apa saja mindset yang harus kamu miliki dalam mempraktekan Bahasa Pria? temui jawabannya di Ebook ini.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                                    <p className="text-collapse-heading">Klik untuk intip isinya…</p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="6">
                                                <Card.Body>

                                                    <div className="row mt-5 mb-3">
                                                        <div className="col-lg-12">
                                                            <div className="bonus-images-and-headline">
                                                                <h4 className="text-center">
                                                                    <b>
                                                                        BONUS Ebook 99 Frasa Pembangkit Kejantanan Pria
                                                                    </b>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-lg-4">
                                                            <div className="d-flex justify-content-center">
                                                                <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-4.png" className="img-fluid" alt="bonus bdtk" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Berisi contekan visual yang memudahkanmu memahami isi materi.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                                    <p className="text-collapse-heading">Klik untuk intip isinya…</p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="7">
                                                <Card.Body>

                                                    <div className="row mt-5 mb-3">
                                                        <div className="col-lg-12">
                                                            <div className="bonus-images-and-headline">
                                                                <h4 className="text-center">
                                                                    <b>
                                                                        BONUS Ebook Texting Revolution - Kitab Texting untuk Meningkatkan Bonding
                                                                    </b>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center mb-5">
                                                        <div className="col-lg-4">
                                                            <div className="d-flex justify-content-center">
                                                                <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-5.png" className="img-fluid" alt="bonus bdtk" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="d-flex justify-content-center my-3">
                                                                <div>
                                                                    <CheckCircle className="bg-white-icon-circle" />
                                                                </div>
                                                                <div className="col-lg-11 col-lg-md-11 col-sm-11 col-11">
                                                                    <p className="li-text-checlist-what-you-give mb-2">
                                                                        Ciptakan dan naikkan level bonding diantara kalian berdua. Via texting? why not
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    {/* end accordion highlight product hlg */}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <a href="https://wa.link/u356sj" rel="noreferrer" target="_blank">
                                            <button type="button" className="btn btn-purple">
                                                <FaTimes />  Tidak Saya Lewatkan Penawaran Eksklusifnya
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <AnchorLink href="#order">
                                            <button type="button" className="btn btn-danger">
                                                <FaCheck />  Ya, Saya Mau, Apa Saja Yang <br /> Didapat
                                            </button>
                                        </AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end  section Six */}

                    {/* section seven */}
                    <div className="container-fluid __bg-oto-bp">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text">
                                        <h2 className="text-white">
                                            <b>
                                                Dapatkan juga Bonus Eksklusif ini, <br /> HANYA JIKA KAMU JOIN HARI INI…

                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                            <div className="row justify-content-center bg-white mrgn-top-200" id="order">
                                <div className="col-lg-12">
                                    {/* wrappe image video pop-up */}
                                    <div className="wrp-all-kit-thumbnail d-flex justify-content-center">
                                        <img className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/bp-mockuop-all-device.png" alt="all-kit" />
                                    </div>
                                    {/* end wrappe image video pop-up */}
                                </div>
                                <div className="col-lg-12">
                                    <div className="card my-5">
                                        <div className="card-body">
                                            {/* wrappe caption product hlg */}
                                            <div className="wrappe-caption-hlg-price">
                                                <p>
                                                    <b>Online Course Bahasa Pria (Rp 1.899.000)</b>
                                                </p>
                                                <p>
                                                    <b>Downloadable Summary dalam format PDF (Rp 899.000)</b>
                                                </p>
                                                <p>
                                                    <b>BONUS Ebook Bahasa Pria Fundamental (Rp 139.000)</b>
                                                </p>
                                                <p>
                                                    <b>BONUS Ebook 99 Frasa Pembangkit</b>
                                                </p>
                                                <p>
                                                    <b>Kejantanan Pria (Rp 139.000)</b>
                                                </p>
                                                <p>
                                                    <b>BONUS Ebook Ebook Texting Revolution (Rp 139.000)</b>
                                                </p>
                                            </div>
                                            {/* end wrappe caption product hlg */}
                                            {/* price hlg */}
                                            <div className="price-hlg-total">
                                                <h3 className="text-center _new-total-value-oto-bp">TOTAL VALUE YANG DIDAPAT SENILAI :</h3>
                                                <h3 className="text-center">Rp 3.215.000</h3>
                                                <p className="text-center">
                                                    Khusus untuk Kamu yang beli hari ini,
                                                    Maka semua manfaat tersebut bisa kamu dapatkan hanya dengan membayar sebesar..
                                                </p>
                                            </div>
                                            <div className="price-hlg-original">
                                                <h2 className="text-center wrp-text-original">
                                                    <span>
                                                        Hanya Rp 299.000
                                                    </span>
                                                </h2>
                                                <h3 className="text-center wrp-text-all-indo">
                                                    Sehingga Total Pembayaran Kamu <br /> Ditambah buku Happy Love Guide <br /> beserta Bonus-bonusnya
                                                    Menjadi Hanya:
                                                </h3>
                                                <h2 className="text-center wrp-text-original">
                                                    <span>
                                                        Rp 598.000
                                                    </span>
                                                </h2>
                                                <div className="price-hlg-total">
                                                    <p className="text-center">
                                                        *pastikan kamu pilih bundling terbatas ini sebelum lanjut ke halaman pembayaran <br />
                                                        **Kesempatan ini terbatas, harga Online Course Bahasa Pria termurah <br />  HANYA bisa kamu dapatkan disini. Segera ambil sekarang juga !
                                                    </p>
                                                </div>
                                            </div>
                                            {/* end price hlg */}
                                            <div className="row my-5">
                                                <div className="col-lg-6">
                                                    <div className="_wrappe-button-oto">
                                                        <a href="https://wa.link/u356sj" rel="noreferrer" target="_blank">
                                                            <button type="button" className="btn btn-purple">
                                                                <FaTimes />  Tidak Saya Lewatkan Penawaran Eksklusifnya
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="_wrappe-button-oto">
                                                        <a href="https://wa.link/en97ob" rel="noreferrer" target="_blank">
                                                            <button type="button" className="btn btn-danger">
                                                                <FaCheck />  Ya, Saya tambahkan <br /> Penawaran Eksklusifnya
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* wrappe headline text */}
                                    <div className="wrappe-headline-top-text">
                                        <h2 className="text-white">
                                            <b>
                                                Masih Ragu? Cek Testimonial dibawah ini!
                                            </b>
                                        </h2>
                                    </div>
                                    {/* end wrappe headline text */}
                                </div>
                            </div>
                            <div className="row justify-content-center ">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-center wrappe-icons-arrow-button">
                                        <FaLongArrowAltDown /> <FaLongArrowAltDown /> <FaLongArrowAltDown />
                                    </div>
                                </div>
                            </div>
                            <div className="row mrgn-top-100">
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-1-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-2-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-3-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-4-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-5-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-6-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-7-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-8-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-9-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-10-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-11-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-12-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-13-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-14-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-15-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-16-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-17-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-18-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-19-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card my-3">
                                        <div className="card-body __testimoni-new-oto-bp-hlg-bottom">
                                            <div className="d-flex justify-content-center">
                                                <img className="img img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testi-20-oto-bp.png" alt="testimoni oto bp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row _top-padding-50-oto-new-hlg-bp">
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <a href="https://wa.link/u356sj" rel="noreferrer" target="_blank">
                                            <button type="button" className="btn btn-purple">
                                                <FaTimes />  Tidak, Saya Lewatkan Penawaran Eksklusifnya
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="_wrappe-button-oto">
                                        <a href="https://wa.link/en97ob" rel="noreferrer" target="_blank">
                                            <button type="button" className="btn btn-danger">
                                                <FaCheck />  Ya, Saya tambahkan <br /> Penawaran Eksklusifnya
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end  section seven */}

                    {/* wrape footer */}
                    <div className="container-fluid bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="text-center text-footer-faq">
                                        © 2021, Built with Lovecoach.id
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end wrape footer */}
                </React.Fragment>

            </>
        )
    }
}
export default Orderstep2;

